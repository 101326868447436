<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import {
  GetDataMethods,
} from "@/state/helpers";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      countPage: 1,
      title: "Users",
      items: [
        {
          text: "Wesal"
        },
        {
          text: "Orders",
          active: true
        }
      ],
      i: 20,
      totalRows: 1,
      ordersData: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "id", sortable: true, label: "Order ID" },
        { key: "user_.id", sortable: false, label: "User ID" },
        { key: "count_units", sortable: false, label: "Count Units" },
        { key: "price", sortable: false, label: "Price" },
        { key: "phone", sortable: false, label: "Phone" },
        { key: "status", sortable: false, label: "Payment Status" },
        { key: "service.name", sortable: false, label: "Service" },
        { key: "service.services_category.name", sortable: false, label: "Category" },
        { key: "type_order", sortable: false, label: "Type Order" },
        { key: "created_at", sortable: false, label: "Created At" },
        { key: "action" }
      ]
    }
  },
  computed: {

    rows() {
      return this.countPage;
    },

  },
  mounted() {
    // Set the initial number of items
    this.GetOrderData()
    this.totalRows = this.ordersData.length;

  },
  watch: {
    currentPage: function () {
      this.GetOrderData(this.currentPage)
    }
  },
  methods: {
    ...GetDataMethods,

    GetOrderData(page) {
      this.getData("get_all?page=" + page).then((res) => {
        this.ordersData = res.data.data.orders
        this.countPage = res.data.data.countPage

      })
    },


    /**
     * Search the table data with search input
     */

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Orders</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <!-- <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="ordersData" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">


                    <template v-slot:cell(user_.id)="data">
                      <router-link :to="{ name: 'user', params: { id: data.value } }">{{ data.value }}</router-link>
                    </template>

                    <template v-slot:cell(created_at)="data">
                      <div>{{ data.value | formatDate }}</div>
                    </template>


                    <template v-slot:cell(status)="data">
                      <div class="badge-soft-success" v-if="data.value == 1"
                        style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex">
                        Success</div>

                      <div class="badge-soft-danger" v-else
                        style="border-radius: 15px; width: 60px;height: 25px;text-align: center;justify-content: center;align-items: center;display: flex;">
                        Faill</div>
                    </template>

                    <template v-slot:cell(action)>
                      <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>

                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <!-- <button  v-for="v in i" :key="v" @click="change_page(v)">{{v}}</button> -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>